@charset "utf-8";

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 8px;
}

.swiper-pagination-bullet {
	position: relative;
  width: 28px;
  height: 28px;
  display: inline-block;
  border-radius: 100%;
  border: 7px solid rgba(76,75,75, 0.37);
  background:  rgba(255,255,255,0.05);
  opacity: 1;
  &:after{
    position: absolute;
    display: block;
    content: '';
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    border-radius: 2000px;
    background-color: $primary;
    transform: translate3d(-50%, -50%, 0);
    opacity: 0;
    transition: opacity 0.25s ease-in-out;
  }
}
.swiper-pagination-bullet-active {
    opacity: 1;
    background: rgba(255,255,255,0.5);
    border-color: $primary;
  &:after{
		opacity: 1;
  }
}

/*
 * Fleche de navigation
 * Style Triangle rouge
 */

.swiper-button-next,
.swiper-button-prev{
  &.is-triangle-rouge{
    width: 22px;
    height: 35px;
    background: transparent;
  }
}
.swiper-button-next{
  &.is-triangle-rouge{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 0 17px 22px;
    border-color: transparent transparent transparent $primary;
  }
}
.swiper-button-prev{
  &.is-triangle-rouge{
    border-style: solid;
    border-width: 17px 22px 17px 0;
    border-color: transparent $primary transparent transparent;
  }
}