body{
  &.has-header-fixed{
    padding-top: $headerNavbarHeight;
  }

  @include respond-to('medium') {
    &.has-header-fixed{
      padding-top: $headerNavbarHeight-medium;
    }
  }
}

.header{
  background-color: $primary;
  .header-wrapper{
    width: $contentWidth;
    max-width: 100%;
    margin: 0 auto;
  }
  &.is-fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: $headerNavbarHeight;
    z-index: 100;
  }

  @include respond-to('medium') {
    &.is-fixed {
      height: $headerNavbarHeight-medium;
    }
  }
}
