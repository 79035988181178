.footer{
  padding: 26px 0 24px 0;
  background-color: $primary;
  > a {
    display: block;
  }
  .container{
    display: flex;
    align-items: center;
    color: $white;
  }
  .logo{
    margin-right: 56px;
  }
  p,
  a{
    // margin: 0; 
    // display: block;
    color: $white;
    font-size: 1.3rem;
  }
  p{}
  a{}
  .footer-mentions{}

  a + p,
  p + a {
    // margin-left: 15px;
  }
}
