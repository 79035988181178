@charset "utf-8"

//Juste pour tester la taille des typos
.textRem{
  font-size: 1.4rem; // diviser par 10
}
.textEm{
  font-size: (14/14)+em; // diviser par 14
}
.textPx{
  font-size: 14px;
}

/* ----------------------------- */
/* ==TYPO                        */
/* ----------------------------- */ 

//>Raleway (sans serif)
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-bold-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-italic-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-italic-webfont.woff') format('woff');
    font-weight: 400;
    font-style: italic;

}
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-light-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-medium-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-mediumitalic-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-mediumitalic-webfont.woff') format('woff');
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-regular-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'raleway';
    src: url('#{$path-font}/raleway-semibold-webfont.woff2') format('woff2'),
         url('#{$path-font}/raleway-semibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on December 9, 2017 */


//>playfair_display (sans serif)
@font-face {
    font-family: 'playfair_display';
    src: url('#{$path-font}/playfairdisplay-bold-webfont.woff2') format('woff2'),
         url('#{$path-font}/playfairdisplay-bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}