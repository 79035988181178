@mixin arrow($color) {
  border: 1px solid $color;
  border-right: 0;
  border-top: 0;
  content: " ";
  display: block;
  height: 0.5em;
  pointer-events: none;
  position: absolute;
  transform: rotate(-45deg);
  transform-origin: center;
  width: 0.5em;
}

@mixin hamburger($dimensions) {
  cursor: pointer;
  display: block;
  height: $dimensions;
  position: relative;
  width: $dimensions;
  span{
    background-color: currentColor;
    display: block;
    height: 1px;
    left: calc(50% - 8px);
    position: absolute;
    transform-origin: center;
    transition-duration: 250ms;
    transition-property: background-color, opacity, transform;
    transition-timing-function: ease-in;
    width: 16px;
    &:nth-child(1){
      top: calc(50% - 6px);
    }
    &:nth-child(2){
      top: calc(50% - 1px);
    }
    &:nth-child(3){
      top: calc(50% + 4px);
    }
  }
  &:hover{
    background-color: rgba(black, 0.05);
  }
  // Modifers
  &.is-active{
    span{
      &:nth-child(1){
        transform: translateY(5px) rotate(45deg);
      }
      &:nth-child(2){
        opacity: 0;
      }
      &:nth-child(3){
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}

@mixin overflow-touch() {
  -webkit-overflow-scrolling: touch;
}


@mixin overlay($offset: 0) {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset;
}

@mixin unselectable() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
