@charset "utf-8";
/*!Doxity @author: HB
 * 
 * Doxity + Knacss
 */

// Import the initial variables
@import "_variables.scss";

// Import Knacss
@import "./../../node_modules/knacss/sass/knacss.scss";

// Import Doxity styles
@import "utilities/_all";
// @import "elements/_all";
@import "components/_all";
@import "layout/_all";

@import "../../node_modules/swiper/dist/css/swiper.min.css"; // Avec la var #{$path-font} cela ne fonctionne pas :(

@import "vendor-override/_all"; // Personnalisation des librairies
