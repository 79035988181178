@charset "utf-8";
/*
 * Variables de KNACSS
*/

// Config file and project variables

// tiny font sizes (mobile first)
$base-font-size : 1.45rem; // ex. 1.4rem would be "14px" equivalent
$line-height    : 1.4 !default;
$h1-size          : 4.0rem;
$h2-size          : 2.3rem; 
$h3-size          : 2.1rem !default;
$h4-size          : 1.8rem; 
$h5-size          : 1.6rem; 
$h6-size          : 1.45rem;

// large font sizes
$base-font-size-l : 1.6rem; // ex. 1.6rem would be "16px" equivalent
$line-height-l    : 1.5 !default;
$h1-size-l        : 4.3rem;
$h2-size-l        : 2.6rem; 
$h3-size-l        : 2.4rem !default;
$h4-size-l        : 2.0rem; 
$h5-size-l        : 1.8rem; 
$h6-size-l        : 1.6rem; 
// 43px 26px 20px 18px 16px(textes) 14px
// font stacks
$font-stack-common    : 'raleway', -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !default; // system font stack
$font-stack-headings  : 'raleway', sans-serif !default; // headings font
$font-stack-monospace : consolas, courier, monospace !default; // monospace font

// color scheme
$color1    : rgb(67, 70, 71); //#585757
$color2    : rgb(255,255,255);
$color3    : rgb(30,30,30);
$color4    : rgb(0,0,0);
$color5    : rgb(172, 7, 61);

// colors used in project
$color-base      : $color1;
$color-link      : $color3;
$background-base : $color2;

// If you don't want any effect on focused/hovered links,
// comment variable below or make it equal to either $color-link or false or null
$color-link-hover: $color4;
$brand-primary: $color5; //Couleur principale


// spacings (choose unit you prefer)
$tiny-value             : .5rem !default; // tiny value for margins / paddings
$tiny-plus-value        : .7rem !default; // tiny+ value for margins / paddings
$small-value            : 1rem !default; // small value for margins / paddings
$small-plus-value       : 1.5rem !default; // small+ value for margins / paddings
$medium-value           : 2rem !default; // medium value for margins / paddings
$medium-plus-value      : 3rem !default; // medium+ value for margins / paddings
$large-value            : 4rem !default; // large value for margins / paddings
$large-plus-value       : 6rem !default; // large value for margins / paddings
$extra-large-value      : 8rem !default; // extra large value for margins / paddings
$extra-large-plus-value : 12rem !default; // extra large value for margins / paddings
$ultra-large-value      : 16rem !default; // ultra large value for margins / paddings
$ultra-large-plus-value : 20rem !default; // ultra large value for margins / paddings

//kna-namespace (default : null)
$kna-namespace: null !default;


/*
 * Variables spécifiques Doxity
 * (Attention de ne pas utiliser un nom de variable utilisé par knacss !)
 */

//->Les typos
$font : 'raleway', sans-serif;
$font2 : 'playfair_display', serif;

//Graisses
$weight-light: 300;
$weight-normal :400;
$weight-medium:500;
$weight-semibold:600;
$weight-bold:700;

//Url
$path-font : '../fonts';
$path-image : '../images';
// $path-node : '../../node_modules'; // Ne fonctionne pas pour les imports en css

//Couleurs
$white : $color2;
$black : $color4;
$light : $color1;
$light-invert : $color3;
$dark : $color3;
$dark-invert : $color1;
$primary : $brand-primary;
$primary-invert : $white;
$success : rgb(17,174,69);
$success-invert : $white;

$colors: ("white": ($white, $black), "black": ($black, $white), 
	"light": ($light, $light-invert), "dark": ($dark, $dark-invert),
	"primary": ($primary, $primary-invert), "success": ($success, $success-invert));

//Font size
$size-1: 4.3rem !default;
$size-2: 2.6rem !default;
$size-3: 2.3rem !default;
$size-4: 2.0rem !default;
$size-5: 1.8rem !default;
$size-6: 1.6rem !default;
$size-7: 1.4rem !default;


//Dimentions
$contentWidth: 1116px;
$contentWidth-medium: 1015px;
$headerNavbarHeight: 100px; // Header navigation hauteur
$headerNavbarHeight-medium: 80px; // Header navigation hauteur en mobile
