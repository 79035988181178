// Overflow

.is-clipped {
  overflow: hidden !important;
}

// Overlay

.is-overlay{
  @include overlay();
}

.is-capitalized{
  text-transform: capitalize !important;
}

.is-lowercase{
  text-transform: lowercase !important;
}

.is-uppercase{
  text-transform: uppercase !important;
}

.is-italic{
  font-style: italic !important;
}

// Text 
.has-text-weight-light{
  font-weight: $weight-light !important;
}
.has-text-weight-normal{
  font-weight: $weight-normal !important;
}
.has-text-weight-medium{
  font-weight: $weight-medium !important;
}
.has-text-weight-semibold{
  font-weight: $weight-semibold !important;
}
.has-text-weight-bold{
  font-weight: $weight-bold !important;
}


// Other

.is-marginless{
  margin: 0 !important;
}

.is-paddingless{
  padding: 0 !important;
}

.is-radiusless{
  border-radius: 0 !important;
}

.is-shadowless{
  box-shadow: none !important;
}

.is-unselectable{
  @include unselectable();
}
