$navbar-background-color: $primary !default;
$navbar-height: 8rem !default;
$navbar-fixed-z: 30 !default;

$navbar-item-color: $white !default;
$navbar-item-hover-color: $dark !default;
$navbar-item-hover-background-color: $background-base !default;
$navbar-item-active-color: $black !default;
$navbar-item-active-background-color: transparent !default;
$navbar-item-img-max-height: 8rem !default;

$navbar-tab-hover-background-color: transparent !default;
$navbar-tab-hover-border-bottom-color: $color-link !default;
$navbar-tab-active-color: $color-link !default;
$navbar-tab-active-background-color: transparent !default;
$navbar-tab-active-border-bottom-color: $color-link !default;
$navbar-tab-active-border-bottom-style: solid !default;
$navbar-tab-active-border-bottom-width: 3px !default;


$navbar-divider-background-color: $light !default;

@mixin navbar-fixed {
  left: 0;
  position: fixed;
  right: 0;
  z-index: $navbar-fixed-z; }

@mixin navbar-fixed-html {
  left: 0;
  position: fixed;
  right: 0; }

.navbar {
  background-color: $navbar-background-color;
  min-height: $navbar-height;
  position: relative;
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    &.is-#{$name} {
      background-color: $color;
      color: $color-invert;
      .navbar-brand {
        & > .navbar-item,
        .navbar-link {
          color: $color-invert; }
        & > a.navbar-item,
        .navbar-link {
          &:hover,
          &.is-active {
            background-color: darken($color, 5%);
            color: $color-invert; } }
        .navbar-link {
          &::after {
            border-color: $color-invert; } } }
      @include respond-to("medium-up") {
        .navbar-start,
        .navbar-end {
          & > .navbar-item,
          .navbar-link {
            color: $color-invert; }
          & > a.navbar-item,
          .navbar-link {
            &:hover,
            &.is-active {
              background-color: darken($color, 5%);
              color: $color-invert; } }
          .navbar-link {
            &::after {
              border-color: $color-invert; } } } } } }
  & > .container {
    align-items: stretch;
    display: flex;
    min-height: $navbar-height;
    width: 100%; }
  &.has-shadow {
    box-shadow: 0 2px 3px rgba($black, 0.1); }
  &.is-fixed-bottom,
  &.is-fixed-top {
    @include navbar-fixed; }
  &.is-fixed-bottom {
    bottom: 0;
    &.has-shadow {
      box-shadow: 0 -2px 3px rgba($black, 0.1); } }
  &.is-fixed-top {
    top: 0; } }

html.has-navbar-fixed-top {
  padding-top: $navbar-height; }

html.has-navbar-fixed-bottom {
  padding-bottom: $navbar-height; }

.navbar-brand,
.navbar-tabs {
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: $navbar-height; }

.navbar-brand {
  .navbar-item {
    background-color: transparent !important; } }
.navbar-tabs {
  @include overflow-touch;
  max-width: 100vw;
  overflow-x: auto;
  overflow-y: hidden; }

.navbar-burger {
  @include hamburger($navbar-height);
  margin-left: auto;
  color: $navbar-item-color; }
.navbar-menu {
  display: none; }

.navbar-item,
.navbar-link {
  color: $navbar-item-color;
  display: block;
  line-height: 1.5;
  font-size: 1.9rem;
  padding: 1.0rem 1.3rem;
  font-weight: $weight-medium;
  position: relative;
  text-decoration: none;
  transition: background-color 0.15s ease-in,  color 0.15s ease-in; }

a.navbar-item,
a.navbar-link {
  &:hover,
  &.is-active {
    background-color: $navbar-item-hover-background-color;
    color: $navbar-item-hover-color; } }

.navbar-item {
  flex-grow: 0;
  flex-shrink: 0;
  img {
    max-height: $navbar-item-img-max-height; }
  &.is-expanded {
    flex-grow: 1;
    flex-shrink: 1; }
  &.is-tab {
    border-bottom: 1px solid transparent;
    min-height: $navbar-height;
    padding-bottom: calc(0.5rem - 1px);
    &:hover {
      background-color: $navbar-tab-hover-background-color;
      border-bottom-color: $navbar-tab-hover-border-bottom-color; }
    &.is-active {
      background-color: $navbar-tab-active-background-color;
      border-bottom-color: $navbar-tab-active-border-bottom-color;
      border-bottom-style: $navbar-tab-active-border-bottom-style;
      border-bottom-width: $navbar-tab-active-border-bottom-width;
      color: $navbar-tab-active-color;
      padding-bottom: calc(0.5rem - #{$navbar-tab-active-border-bottom-width}); } } }

.navbar-content {
  flex-grow: 1;
  flex-shrink: 1; }

.navbar-link {
  padding-right: 2.5em; }

.navbar-divider {
  background-color: $navbar-divider-background-color;
  border: none;
  display: none;
  height: 1px;
  margin: 0.5rem 0; }

@include respond-to("medium") {
  .navbar > .container {
    display: block; }
  .navbar-brand,
  .navbar-tabs {
    .navbar-item {
      align-items: center;
      display: flex; } }
  .navbar-menu {
    background-color: $navbar-background-color;
    box-shadow: 0 8px 16px rgba($black, 0.1);
    padding: 0.5rem 0;
    &.is-active {
      display: block; } }
  // Fixed navbar
  .navbar {
    &.is-fixed-bottom-touch,
    &.is-fixed-top-touch {
      @include navbar-fixed; }
    &.is-fixed-bottom-touch {
      bottom: 0;
      &.has-shadow {
        box-shadow: 0 -2px 3px rgba($black, 0.1); } }
    &.is-fixed-top-touch {
      top: 0; }
    &.is-fixed-top,
    &.is-fixed-top-touch {
      .navbar-menu {
        @include overflow-touch;
        max-height: calc(100vh - #{$navbar-height});
        overflow: auto; } } }
  html.has-navbar-fixed-top-touch {
    padding-top: $navbar-height; }
  html.has-navbar-fixed-bottom-touch {
    padding-bottom: $navbar-height; } }

@include respond-to("medium-up") {
  .navbar,
  .navbar-menu,
  .navbar-start,
  .navbar-end {
    align-items: stretch;
    display: flex; }
  .navbar {
    min-height: $navbar-height;
    &.is-transparent {
      background-color: transparent !important;
      a.navbar-item,
      a.navbar-link {
        &:hover,
        &.is-active {
          background-color: transparent !important; } } } }

  .navbar-burger {
    display: none; }
  .navbar-item,
  .navbar-link {
    align-items: center;
    display: flex; }
  .navbar-item {
    position: relative;
    &:after {
      display: block;
      content: '';
      position: absolute;
      bottom: 26px;
      right: 1rem;
      left: 1rem;
      height: 3px;
      background-color: $white;
      opacity: 0; } }

  .navbar-link {
    &::after {
      // +arrow($navbar-dropdown-arrow)
      margin-top: -0.375em;
      right: 1.125em;
      top: 50%; } }
  .navbar-menu {
    flex-grow: 1;
    flex-shrink: 0; }
  .navbar-start {
    justify-content: flex-start;
    margin-right: auto;
    //On va forcer a prendre toute la largeur possible
    width: 100%;
    max-width: 790px;
    justify-content: space-around; }
  .navbar-end {
    justify-content: flex-end;
    margin-left: auto; }
  .navbar-divider {
    display: block; }
  .navbar-brand {
    .navbar-item {
      background-color: transparent !important;
      img {
        height: 8rem; }
      &:after {
        display: none; } } }
  .navbar > .container,
  .container > .navbar {
    .navbar-brand {
      margin-left: -1rem; }
    .navbar-menu {
      margin-right: -1rem; } }
  // Fixed navbar
  .navbar {
    &.is-fixed-bottom-desktop,
    &.is-fixed-top-desktop {
      @include navbar-fixed; }
    &.is-fixed-bottom-desktop {
      bottom: 0;
      &.has-shadow {
        box-shadow: 0 -2px 3px rgba($black, 0.1); } }
    &.is-fixed-top-desktop {
      top: 0; } }
  html.has-navbar-fixed-top-desktop {
    padding-top: $navbar-height; }
  html.has-navbar-fixed-bottom-desktop {
    padding-bottom: $navbar-height; }
  // Hover/Active states
  a.navbar-item,
  a.navbar-link {
    &.is-active {
      color: $navbar-item-active-color; }
    &.is-active:not(:hover) {
      color: $navbar-item-color;
      background-color: $navbar-item-active-background-color;
      &:after {
        opacity: 1; } } } }


